@font-face {
	font-family: "Bahij-light";
	src: url("../assets/fonts/Bahij_TheSansArabic-Light.ttf");
	font-weight: normal;
	font-style: normal;
}
input {
	outline: none;
	border: none;
}
button {
	outline: none !important;
	border: none;
	background: transparent;
}
.logo-login {
	width: 300px;
	display: block;
	margin: 0 auto 40px auto;
}
.container-login100 {
	width: 100%;
	min-height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		158deg,
		rgba(255, 255, 255, 1) -500%,
		rgba(57, 73, 109, 1) 42%,
		rgba(19, 38, 81, 1) 100%
	);
}
.wrap-login100 {
	width: 560px;
}
.login100-form {
	width: 100%;
	background-color: transparent;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
}
.wrap-input100 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	width: 50%;
	height: 75px;
	position: relative;
	border: 1px solid #e0e0e0;
	border-bottom: none;
	background-color: #fff;
}
.wrap-input100.rs1 {
	border-top-right-radius: 12px;
	border-right: none;
}
.wrap-input100.rs2 {
	border-top-left-radius: 12px;
}
.input100 {
	font-family: "Bahij-light";
	font-size: 15px;
	color: #555555;
	line-height: 1.2;
	display: block;
	width: 100%;
	background: transparent;
	padding: 0 30px;
}
input.input100 {
	height: 100%;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}
.container-login100-form-btn {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.login100-form-btn {
	font-family: "Bahij-light";
	font-size: 15px;
	color: #fff;
	line-height: 1.2;
	text-transform: uppercase;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	width: 100%;
	height: 70px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	overflow: hidden;
	background: #111111;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
	position: relative;
	z-index: 1;
	&::before {
		content: "";
		display: block;
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(
			158deg,
			rgba(255, 255, 255, 1) -500%,
			rgba(57, 73, 109, 1) 42%,
			rgba(19, 38, 81, 1) 100%
		);
		-webkit-transition: all 0.4s;
		-o-transition: all 0.4s;
		-moz-transition: all 0.4s;
		transition: all 0.4s;
	}
	&:hover {
		background-color: transparent;
		&:before {
			opacity: 1;
		}
	}
}
@media (max-width: 576px) {
	.wrap-input100 {
		width: 100%;
	}
	.wrap-input100.rs1 {
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		border-right: 1px solid #e0e0e0;
	}
	.wrap-input100.rs2 {
		border-radius: 0;
	}
}
