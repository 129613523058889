@import "Variables";
//fonts
@font-face {
	font-family: "Bahij-light";
	src: url("../assets/fonts/Bahij_TheSansArabic-Light.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Bahij-bold";
	src: url("../assets/fonts/Bahij_TheSansArabic-Bold.ttf");
	font-weight: normal;
	font-style: normal;
}



// globals
body {
	font-family: "Bahij-light" !important;
	overflow-x: hidden;
}

.Toastify__toast--rtl {
	font-family: "Bahij-light" !important;
}

a:hover {
	text-decoration: none !important;
}

// layout
.main-wrapper {
	width: 100%;
}

.content-wrapper {
	width: 85%;
	background: #f3faff;
	position: fixed;
	overflow-y: scroll;
	overflow-x: hidden;
	right: calc(100% - 85%);
	height: 100%;

	.content-container {
		width: 95%;
		margin: 0 auto;
	}
}

.sidebar::-webkit-scrollbar {
	display: none;
}

// sidebar
.sidebar {
	width: 15%;
	overflow: scroll;
	background: #ffffff;
	-ms-overflow-style: none;
	scrollbar-width: none;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	border-left: 1px solid #eeeeee;

	.logo {
		width: 100%;
		padding: 30px 0;
		border-bottom: 1px solid #eee;

		img {
			width: 60%;
			display: block;
			margin: 0 auto;
		}
	}

	.sidebar-menu {
		margin: 35px 0 25px 0;

		li {
			margin: 0 !important;
		}

		li a {
			display: inline-block;
			margin: 0 10px;
			width: 45px;
			height: 45px;
			line-height: 45px;
			background: #fbfbfc;
			border-radius: 50%;
			border: 1px solid #f0f0f8;
			color: #747684;
			cursor: pointer;
		}
	}

	#sidebar-menu {
		.no-child {
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			color: #646464;
			transition: $transition;
			position: relative;
			width: 100%;
			border-bottom: 1px solid #f0f0f0;

			i {
				margin-left: 10px;
			}

			&:hover .hover {
				opacity: 1;
				visibility: visible;
			}

			&:hover span,
			&:hover .change-color {
				color: #ffffff;
			}

			.hover {
				background: rgb(255, 255, 255);
				background: linear-gradient(
					158deg,
					rgba(255, 255, 255, 1) 0-500%,
					rgba(57, 73, 109, 1) 21%,
					rgba(44, 109, 255, 1) 100%
				);
				width: 80%;
				height: 55px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				border-top-left-radius: 35px;
				border-bottom-left-radius: 35px;
				opacity: 0;
				visibility: hidden;
				z-index: 10;
			}

			.link {
				position: relative;
				z-index: 100;
			}

			.arrow-left {
				font-size: 12px;
			}
		}

		.side-item {
			border-bottom: 1px solid #f0f0f0;

			button {
				padding: 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 16px;
				color: #646464;
				transition: $transition;
				position: relative;
				width: 100%;

				i {
					margin-left: 10px;
				}

				&:hover .hover {
					opacity: 1;
					visibility: visible;
				}

				&:hover span,
				&:hover .change-color {
					color: #ffffff;
				}
			}

			.hover {
				background: rgb(255, 255, 255);
				background: linear-gradient(
					158deg,
					rgba(255, 255, 255, 1) 0-500%,
					rgba(57, 73, 109, 1) 21%,
					rgba(44, 109, 255, 1) 100%
				);
				width: 80%;
				height: 55px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				border-top-left-radius: 35px;
				border-bottom-left-radius: 35px;
				opacity: 0;
				visibility: hidden;
				z-index: 10;
			}

			.link {
				position: relative;
				z-index: 100;
			}

			.arrow-left {
				font-size: 12px;
			}

			ul {
				padding: 0 30px;

				a {
					display: inline-block;
					padding: 10px 5px;
					color: $blue;
					width: 100%;
					transition: $transition;

					&:hover {
						padding-right: 15px;
					}
				}
			}
		}
	}
}

// header
header {
	height: 250px;
	width: 100%;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		158deg,
		rgba(255, 255, 255, 1) -500%,
		rgba(57, 73, 109, 1) 42%,
		rgba(19, 38, 81, 1) 100%
	);
	padding: 25px 0;

	.menu-button {
		background: none;
		border: none;
		font-size: 30px;
		color: #ffffff;
	}

	.header-border {
		background: rgb(250, 250, 250);
		background: linear-gradient(
			122deg,
			rgb(223, 232, 255) 4%,
			#132651 95%
		);
		width: 100%;
		height: 1px;
		margin: 20px 0 30px 0;
	}

	.header-list {
		float: left;

		li {
			a {
				display: inline-block;
				width: 35px;
				height: 35px;
				line-height: 35px;
				border-radius: 50%;
				border: 1px solid #f0f0f8;
				color: #747684;
				text-align: center;
			}

			button {
				display: inline-block;
				margin: 0 10px;
				width: 35px;
				height: 35px;
				line-height: 30px;
				border-radius: 50%;
				border: 1px solid #f0f0f8;
				color: #747684;
				text-align: center;
				background: none;
			}
		}
	}

	h1 {
		margin: 0;
		color: #ffffff;
		font-size: 20px;
	}

	.breadcrumbs {
		float: left;

		li {
			color: #ffffff;
			margin: 0 5px;

			a {
				color: #ffffff;
			}
		}
	}

	.logo-sm {
		width: 100%;
		margin-top: 20px;
	}
}

// content
.content {
	background: #ffffff;
	width: 90%;
	position: absolute;
	top: 200px;
	left: 0;
	right: 0;
	margin: 0 auto;
	border-radius: 20px;
	padding: 30px 30px 100px 30px;
	min-height: 500px;

	.page-meta {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 40px;

		.main-title {
			font-size: 18px;
			margin: 0;
		}
	}
}

// toggle classes
.sidebar-sm {
	width: 5%;

	&:hover {
		width: 15%;
	}

	.logo img {
		width: 70%;
	}

	&:hover .logo img {
		width: 40%;
	}

	.sidebar-menu {
		a {
			margin-bottom: 6px !important;
		}
	}

	.link span {
		display: none;
	}

	.arrow-left {
		display: none;
	}

	&:hover .link span {
		display: inline-block;
	}

	&:hover .arrow-left {
		display: inline-block;
	}

	button {
		display: inline-block !important;
		text-align: center;
	}

	.no-child {
		display: block !important;
		text-align: center;
	}

	&:hover .no-child {
		display: flex !important;
	}

	&:hover button {
		display: flex !important;
	}
}

.wrapper-sm {
	width: 95% !important;
	right: calc(100% - 95%);
}

footer {
	width: 100%;
	background: #ffffff;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	text-align: center;
	border-top: 1px solid #eeeeee;

	p {
		margin: 0;
		padding: 10px 0;
		text-align: center;
		direction: ltr;
	}

	img {
		width: 60px;
	}
}

.notifications .list::-webkit-scrollbar {
	display: none !important;
}

.notifications {
	position: fixed;
	left: -500px;
	top: 0;
	bottom: 0;
	width: 15%;
	z-index: 1000;
	background: #ffffff;
	border-right: 1px solid #eeeeee;
	transition: $transition;

	.header {
		padding: 20px 0;
		border-bottom: 1px solid #eeeeee;
		margin-bottom: 20px;
		position: relative;

		h2 {
			font-size: 18px;
			margin: 0;
			text-align: center;
		}

		button {
			position: absolute;
			right: 10px;
			top: 18px;
			background: none;
			border: none;
			color: #000;
			transition: $transition;

			&:hover {
				color: red;
			}
		}
	}

	.list {
		overflow-y: scroll;
		height: 100vh;
		padding-bottom: 100px;
		scrollbar-width: none;

		li {
			transition: all ease-in-out 0.2s;

			a {
				display: inline-block;
				padding: 15px;
				border-bottom: 1px solid #eeeeee;
				color: black;
				font-size: 15px;
			}

			h6 {
				margin: 10px 0 0 0;
				font-size: 13px;
				color: $blue;
			}

			&:hover {
				background: #eeeeee;
			}
		}
	}

	.nav-tabs {
		border: none;
		display: flex;
		justify-content: center;
	}

	.nav-tabs .nav-link {
		border: none;
		color: black;
	}

	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs .nav-link.active {
		border: none;
		color: $blue;
	}

	.tab-content {
		margin-top: 20px;
	}
}

.notifications-toggle {
	left: 0;
}

.bt {
	display: inline-block;
	padding: 10px 30px;
	color: #ffffff;
	border-radius: 5px;
	transition: $transition;

	&:hover {
		color: #ffffff;
	}
}

.bt-success {
	background: $success;

	&:hover {
		background: #26b37f;
	}
}

.bt-danger {
	background: $danger;

	&:hover {
		background: #b1263a;
	}
}

.bt-primary {
	background: rgb(255, 255, 255);
	background: linear-gradient(
		158deg,
		rgba(255, 255, 255, 1) 0-500%,
		rgba(57, 73, 109, 1) 21%,
		rgba(44, 109, 255, 1) 100%
	);
}

.bt-sm {
	padding: 5px 10px !important;
}

.settingsList {
	margin: 0 !important;
	padding: 0 !important;

	li {
		margin-left: 5px;
	}
}

.react-confirm-alert-body {
	direction: ltr;
	text-align: right !important;
	font-family: "Bahij-light" !important;
}

.react-confirm-alert-body > h1 {
	font-size: 25px;
}

.form-item {
	margin-bottom: 30px;

	input,
	select {
		width: 100%;
		background: #fff;
		border: 1px solid #ccc;
		height: 50px;
		border-radius: 5px;
		padding: 10px;
		transition: $transition;

		&:focus,
		&:active {
			outline: none;
			border: 1px solid $blue !important;
		}

		&:hover {
			border: 1px solid $blue;
		}
	}

	textarea {
		width: 100%;
		background: #fff;
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 10px;
		transition: $transition;

		&:focus,
		&:active {
			outline: none;
			border: 1px solid $blue !important;
		}

		&:hover {
			border: 1px solid $blue !important;
		}
	}
}

.selectbox,
.css-yk16xz-control,
.css-g1d714-ValueContainer {
	height: 45px !important;
}

.dropzoneContainer {
	width: 100%;
	background: #ffffff;
	padding: 15px;
	border: 1px solid #cccccc;
	border-radius: 5px;

	.dropzone {
		background: #fafafa;
		padding: 20px;
		border: 2px dashed #eee;
		border-radius: 5px;
		text-align: center;
		cursor: pointer;

		p {
			margin: 0;
		}
	}

	.thumbsContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 10px;
	}

	.thumb {
		display: inline-flex;
		border-radius: 2px;
		border: 1px solid #eaeaea;
		margin: 10px;
		width: 100px;
		height: 100px;
		padding: 4px;
		box-sizing: border-box;
	}

	.thumbInner {
		display: flex;
		min-width: 0px;
		overflow: hidden;

		.img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
	background: #ffffff !important;
	color: #000 !important;
	border: 1px solid #cccccc !important;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
	display: none !important;
}

.perItem {
	background: #eeeeee;
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 30px;

	h1 {
		font-size: 18px !important;
		margin: 0 0 20px 0;
	}

	ul {
		margin: 0;
	}

	label {
		margin-right: 5px !important;
	}
}

.imagePreview {
	width: 100%;
	border-radius: 10px;
	margin-top: 20px;
}

.imagePreviewSm {
	width: 200px;
	margin-top: 30px;
}

.nestable {
	text-align: left;
}

.nestable-item {
	position: relative;
	background: #eeeeee;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 10px;
}

.menu-item {
	width: 100%;
	height: 45px;
	border: 1px solid #cccccc;
	border-radius: 5px;
	margin-bottom: 10px;
	position: relative;

	input {
		width: 100%;
		height: 100%;
		border: none;
		border-radius: 5px;
		padding: 0 10px;
	}

	button {
		position: absolute;
		left: 10px;
		top: 10px;
		background: none;
		border: none;
		color: red;
	}
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable),
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
	background: #f6f6f6 !important;
}

.loader {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #000000d4;
	border-radius: 20px;
	z-index: 1000;

	i {
		font-size: 60px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #ffffff;
	}
}

.lst {
	li {
		margin-left: 15px;
	}
}

.datatable {
	position: relative;
	z-index: 100;
}

.modal-wrapper {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: #0000009e;
	display: none;
	z-index: 10000;

	.as-modal {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 80%;
		height: 80%;
		margin: auto auto;
		background: #ffffff;
		z-index: 10000;
		border-radius: 10px;

		.as-header {
			border-bottom: 1px solid #eeeeee;
			padding: 20px;
		}

		.as-body {
			padding: 30px;
			height: 80%;
			overflow-y: scroll;
		}

		.as-footer {
			position: absolute;
			bottom: 0;
			left: 20px;
			right: 0;
			width: 100%;
			margin: 0 auto;
			border-top: 1px solid #eeeeee;
			padding-top: 20px;
			background: #ffffff;

			ul {
				float: left;
			}
		}
	}
}

.modal-wrapper-show {
	display: block;
}

.data-table-extensions-action {
	display: none;
}

// responsive
@media only screen and (max-width: 1350px) {
	.sidebar {
		width: 20%;
	}
	.content-wrapper {
		width: 80%;
		right: calc(100% - 80%);
	}
	.notifications {
		width: 20%;
	}
	.sidebar-sm {
		width: 7%;
	}
	.wrapper-sm {
		width: 93%;
		right: calc(100% - 93%);
	}
}

@media only screen and (max-width: 1000px) {
	.sidebar {
		width: 25%;
	}
	.content-wrapper {
		width: 75%;
		right: calc(100% - 75%);
	}
	.notifications {
		width: 28%;
	}
	.sidebar-sm {
		width: 7%;
	}
	.wrapper-sm {
		width: 93%;
		right: calc(100% - 93%);
	}
}

@media only screen and (max-width: 800px) {
	.sidebar {
		width: 30%;
	}
	.content-wrapper {
		width: 70%;
		right: calc(100% - 70%);
	}
	.notifications {
		width: 35%;
	}
	.sidebar-sm {
		width: 12%;
	}
	.wrapper-sm {
		width: 88% !important;
		right: calc(100% - 88%);
	}
}

@media only screen and (max-width: 767px) {
	header,
	main-wrapper {
		overflow: hidden;
	}
	.sidebar {
		width: 65%;
		position: fixed;
		right: -500px;
		top: 0;
		bottom: 0;
		z-index: 1000;
		transition: $transition;

		.sidebar-nav a {
			font-size: 15px !important;
		}
	}
	.sidebar-responsive {
		right: 0;
	}
	.sm-wrapper {
		width: 35%;
		left: -200px;
		top: 0;
		bottom: 0;
		height: 100vh;
		position: fixed;
		background: #00000091;
		transition: $transition;
		z-index: 1000;
	}
	.sm-wrapper-toggle {
		left: 0;
	}
	.content-wrapper {
		width: 100%;
		right: 0;
	}
	.header-list {
		margin-top: 23px;

		li {
			margin: 0 !important;
		}
	}
	.breadcrumbs {
		float: none !important;
		text-align: center;
		padding: 0 !important;
	}
	header {
		height: 207px;
	}
	.content {
		position: static;
		margin: 20px auto 75px auto;
	}
	.notifications {
		width: 65%;
	}
	.nav-link {
		padding: 0 5px !important;
	}
	.not-sm-wrapper {
		width: 35%;
		right: -200px;
		top: 0;
		bottom: 0;
		height: 100vh;
		position: fixed;
		background: #00000091;
		transition: $transition;
		z-index: 1000;
	}
	.not-sm-wrapper-toggle {
		right: 0;
	}
}

@media only screen and (max-width: 500px) {
	.page-meta {
		display: inline-block !important;
		text-align: center;
		width: 100%;

		a {
			display: inline-block;
			margin-top: 20px;
		}
	}
	.lst {
		li {
			display: inline-block;
			width: 100%;
			text-align: center;
			margin: 0 0 15px 0 !important;

			button {
				width: 100%;
			}
		}
	}
	.as-footer {
		padding: 0 20px;

		ul {
			float: none !important;
			padding: 0 !important;
		}
	}
	.homePage {
		a {
			margin-bottom: 15px !important;
		}
	}
}

// delete after this
.serviceItem {
	background: #ffffff;
	padding: 20px;
	margin-bottom: 25px;
	text-align: center;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	border: 1px solid #eeeeee;

	button {
		position: absolute;
		left: -100px;
		top: 10px;
		background: none;
		border: none;
		color: red;
		transition: all ease 0.5s;
	}

	&:hover button {
		left: 10px;
	}

	h1 {
		font-size: 18px;
		color: #000000;
		margin: 20px 0 10px 0;
		height: 24px;
		overflow: hidden;
	}

	p {
		margin: 0;
		height: 24px;
		overflow: hidden;
	}

	img {
		width: 70px;
		height: 70px;
		margin: 0 auto;
		display: block;
		border-radius: 50%;
		object-fit: cover;
	}
}

.pointList {
	li {
		background: #eeeeee;
		padding: 10px;
		border-radius: 5px;
		margin-bottom: 10px;

		span {
			margin-left: 10px;
			//font-weight: bold;
		}

		button {
			background: none;
			border: none;
			color: red;
			margin-left: 10px;
		}
	}
}

.itemTitle {
	background: #eeeeee;
	padding: 10px;
	border-radius: 5px;
	margin: 15px 0;
	font-size: 18px;
}

.serviceItem {
	background: #ffffff;
	padding: 20px;
	margin-bottom: 25px;
	text-align: center;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	border: 1px solid #eeeeee;

	button {
		position: absolute;
		left: -100px;
		top: 10px;
		background: none;
		border: none;
		color: red;
		transition: all ease 0.5s;
	}

	&:hover button {
		left: 10px;
	}

	h1 {
		font-size: 18px;
		color: #000000;
		margin: 20px 0 10px 0;
		height: 24px;
		overflow: hidden;
	}

	p {
		margin: 0;
		height: 24px;
		overflow: hidden;
	}

	img {
		width: 70px;
		height: 70px;
		margin: 0 auto;
		display: block;
		border-radius: 50%;
		object-fit: cover;
	}
}

.image-preview {
	width: 100%;
	height: 120px;
	position: relative;
	margin-top: 30px;

	button {
		position: absolute;
		left: 10px;
		top: 10px;
		background: #000000;
		border: none;
		color: red;
		border-radius: 5px;
	}
}

.imgList {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
	position: absolute;
}

.opinion {
	background: #eeeeee;
	display: flex;
	justify-content: space-between;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 5px;

	a {
		color: black;
	}

	button {
		background: none;
		border: none;
		color: red;
	}
}

.employees {
	width: 100%;
	height: 250px;
	background: #ffffff;
	border-radius: 10px;
	margin-bottom: 30px;
	text-align: center;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 10px;
	}

	h4 {
		margin: 20px 0 10px 0;
		font-size: 17px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		background: #000000;
		padding: 15px;
		text-align: center;
		margin: 0;
		color: #ffffff;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	button {
		position: absolute;
		left: 10px;
		top: 10px;
		background: #000000;
		width: 40px;
		height: 40px;
		border: none;
		color: red;
		z-index: 1000;
		border-radius: 50%;
	}
}

.filterSettings {
	text-align: center;

	li {
		margin: 0 15px;
	}
}

.homePage {
	text-align: center;

	ul {
		margin-top: 45px;

		li {
			margin: 0 !important;

			a {
				display: inline-block;
				border: 1px solid #eeeeee;
				padding: 10px 20px;
				margin: 0 15px;
				border-radius: 5px;
				transition: $transition;

				&:hover {
					color: black;
					border: 1px solid #cccccc;
				}
			}
		}
	}
}
